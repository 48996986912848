import { FC } from 'react';
import { Grid } from '@mui/material';

import { TransactionsWidget } from './TransactionsWidget';

interface TotalTransactionsProps {
	data?: any;
}

export const TransactionsThreeWidgets: FC<TotalTransactionsProps> = ({ data }) => {
	let manipulateData = data?.reduce((acc:any, x:any) => {
		acc[x.paymentType] = x.totalAmount;
		return acc;
	}, {});

	return (
		<>
			<Grid container spacing={4}>
				{manipulateData?.cash &&
					<Grid item xs={12} sm={3}>
						<TransactionsWidget data={manipulateData?.cash} title={'cash'} />
					</Grid>
				}

				{manipulateData?.card &&
					<Grid item xs={12} sm={3}>
						<TransactionsWidget data={manipulateData?.card} title={'creditCard'} />
					</Grid>
				}

				{manipulateData?.cheque &&
					<Grid item xs={12} sm={3}>
						<TransactionsWidget data={manipulateData?.cheque} title={'cheque'} />
					</Grid>
				}

				{manipulateData?.transfer &&
					<Grid item xs={12} sm={3}>
						<TransactionsWidget data={manipulateData?.transfer} title={'transfer'} />
					</Grid>
				}

				{/* {manipulateData?.casa &&
					<Grid item xs={12} sm={3}>
						<TransactionsWidget data={manipulateData?.casa} title={'casa'} />
					</Grid>
				}

				{manipulateData?.gymlib &&
					<Grid item xs={12} sm={3}>
						<TransactionsWidget data={manipulateData?.gymlib} title={'gymlib'} />
					</Grid>
				} */}
			</Grid>
		</>
	);
};

import { FC } from 'react';
import { Typography, Box, Grid, Card, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CreditCard } from '../../config/constants';

interface TotalTransactionsProps {
	data?: any;
	title?: any;
}

export const TransactionsWidget: FC<TotalTransactionsProps> = ({ data, title }) => {
	let { t } = useTranslation();

	const formatNumber = (value:any) => {
		let num:any = parseFloat(value);
		num = num % 1 !== 0 ? num.toFixed(2) : num.toFixed(0);
		return new Intl.NumberFormat('en-GB').format(parseFloat(num))
	};

	return (
		<>
			<Card sx={{ height: '100%', border: '1px solid #E4E4E7', borderRadius: '10px', boxShadow: 'unset' }}>
				<CardContent sx={{ padding: '25px 30px 20px', height: '100%' }}>
					<Grid container spacing={2}>
						<Grid item xs={10}>
							<Typography
								sx={{
									color: '#727272',
									fontSize: '12px',
									fontWeight: 500,
									lineHeight: '21px',
									marginBottom: '15px',
									textTransform: 'uppercase',
									letterSpacing: '1.5px'
								}}
							>
								{t(`${title}`)}
							</Typography>
							<Box
								sx={{
									color: '#333',
									fontSize: '21px',
									fontWeight: 600,
									lineHeight: '40px',
									display: 'flex',
									alignItems: 'center',
									overflow: 'hidden',
								}}
							>
								€{data ? formatNumber(data): 0}
							</Box>
						</Grid>{' '}
						<Grid item xs={2}>
							<Box
								component="span"
								sx={{
									background: '#FBF0CF',
									width: '40px', // explicitly set the total width
									height: '40px', // explicitly set the total height
									padding: '5px', // padding is now inside the 40px
									borderRadius: '50%', // 50% ensures a perfect circle
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									boxSizing: 'border-box', 
									fontSize: title !== 'creditCard' ? '21px': 'unset',
									fontWeight: title !== 'creditCard' ? 600: 'unset'
								}}
							>
								{title !== 'creditCard' ? (
									'€'
								) : (
									<img src={CreditCard} alt="booking-img" height="18px" width="22px" />
								)}
							</Box>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</>
	);
};

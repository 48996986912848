import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	IconButton,
	Typography,
	FormControl,
	Divider,
	Button,
	Grid,
	Box,
	Select,
	MenuItem,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { styled } from '@mui/system';

import { FilterAlt as FilterAltIcon, Close as CloseIcon } from '@mui/icons-material';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import _ from 'lodash';
import { ProductPurchaseStatus } from 'app/config/constants';
import { useLocationQuery, useLocationQuerySet } from 'app/shared/util/useLocationQuery';
import { enumtoArrayConverter } from 'app/shared/util/common';

interface IFilterProps {
	callback?: any;
	clubsList?: any;
	isPurchasePage?: boolean;
}

const ButtonWrapper = styled('div')`
	display: flex;
	justify-content: flex-end;
`;

const brownButtonStyle = {
	backgroundColor: '#333333',
	color: 'white', // Text color
};

const UserProductPurchaseFilterDialog: React.FC<IFilterProps> = ({ callback }) => {
	const searchParams = useLocationQuery();
	const setQuery = useLocationQuerySet();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const { t } = useTranslation();
	const fields: any = ['clubs', 'filter_transactionStatus'];

	const handleOnChange = (objectKey: string, setFieldValue: any) => (e: any) => {
		setFieldValue(objectKey, e?.target?.value);
	};

	const [open, setOpen] = React.useState(false);
	const [placement] = React.useState<PopperPlacementType>();

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
		setOpen((prev) => !prev);
	};

	const handleReset = (resetForm: any) => (e: any) => {
		resetForm();

		const filteredSearchParamsObject = _.omit(searchParams, fields);
		setQuery(filteredSearchParamsObject);
		callback();
		setOpen(false);
	};

	const handleApply = () => {
		handleClose();
	};

	const handleClose = () => {
		setAnchorEl(null);
		setOpen(false);
	};

	return (
		<>
			<Button
				variant="outlined"
				onClick={handleClick}
				style={{
					cursor: 'pointer',
					borderRadius: '4px',
					border: '1px solid #F2F2F2',
					color: '#727272',
				}}
			>
				<FilterAltIcon style={{ marginRight: '8px', color: '#727272' }} />
				<Typography>{t('filter')}</Typography>
			</Button>

			<Popper
				sx={{ zIndex: 1200, minWidth: '430px', maxWidth: '430px' }}
				open={open}
				anchorEl={anchorEl}
				placement={placement}
				transition
			>
				{({ TransitionProps }) => (
					<Fade {...TransitionProps} timeout={350}>
						<Paper>
							<Grid
								container
								justifyContent={'space-between'}
								alignItems={'center'}
								sx={{ padding: '20px 24px' }}
							>
								<Grid>
									<Typography sx={{ fontSize: '18px', fontWeight: '600' }}>
										{t('filterBy')}
									</Typography>
								</Grid>
								<Grid>
									<IconButton onClick={handleClose}>
										<CloseIcon />
									</IconButton>
								</Grid>
							</Grid>

							<Divider />

							<Box sx={{ padding: '30px' }}>
								<Formik
									initialValues={{ ...searchParams }}
									onSubmit={(values, actions) => {
										setQuery({ ...searchParams, ...values, page: 1 });
										callback({ ...searchParams, ...values, page: 1 });
									}}
									enableReinitialize
								>
									{({ values, setFieldValue, resetForm }) => {
										return (
											<>
												<Form>
													<>
														{' '}
														<FormControl
															fullWidth
															style={{ marginTop: '25px' }}
														>
															<Box className="filter-label">
																{t('status')}
															</Box>
															<Select
																name="filter_transactionStatus"
																value={
																	values?.filter_transactionStatus
																		? values?.filter_transactionStatus
																		: ''
																}
																onChange={(e) => {
																	setFieldValue(
																		`filter_transactionStatus`,
																		e?.target?.value
																	);
																}}
															>
																{enumtoArrayConverter(
																	ProductPurchaseStatus
																)
																	?.filter(
																		(x: any) =>
																			x?.value ===
																				'confirmed' ||
																			x?.value === 'cancelled'
																	)
																	?.map((type: any) => (
																		<MenuItem
																			key={type?.value}
																			value={type?.value}
																		>
																			{type?.label !== ''
																				? type?.label
																				: t('noName')}
																		</MenuItem>
																	))}
															</Select>{' '}
														</FormControl>
													</>
													<Grid
														container
														justifyContent={'flex-end'}
														alignItems={'center'}
														mt={'3rem'}
													>
														<Button
															onClick={handleReset(resetForm)}
															style={{
																marginRight: '1rem',
																color: '#EB5757',
																fontWeight: 600,
																textTransform: 'capitalize',
															}}
														>
															{t('resetFilters')}
														</Button>
														<ButtonWrapper>
															<Button
																variant="contained"
																color="primary"
																onClick={handleApply}
																style={brownButtonStyle}
																className="capitalize"
																type="submit"
																sx={{ height: '44px' }}
															>
																{t('applyFilters')}
															</Button>
														</ButtonWrapper>
													</Grid>
												</Form>{' '}
											</>
										);
									}}
								</Formik>
							</Box>
						</Paper>
					</Fade>
				)}
			</Popper>
		</>
	);
};

export default UserProductPurchaseFilterDialog;
